import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  momentTimezone,
  Eventcalendar,
  Snackbar,
  setOptions,
  MbscCalendarEvent,
  MbscEventCreatedEvent,
  MbscEventClickEvent,
  MbscPageChangeEvent,
  MbscEventDragEvent,
} from '@mobiscroll/react';
import { FormikValues } from 'formik';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getRRule,
  formatAllDayDate,
  formatUntilDateString,
  convertKeysCase,
} from '@config/helpers';
import {
  IEvent,
  ICalendarScheduleType,
  IRecurringDeleteOption,
  IOAuthAccount,
} from '@config/models';
import { RootState } from '@config/store';
import CalendarHeader from '@modules/calendar/components/CalendarHeader';
import EventForm from '@modules/calendar/components/EventForm';
import {
  retrieveEvents,
  retrieveCalendars,
  updateEvent,
  updateEventData,
  createEvent,
  createEventData,
  deleteEvent,
  retrieveOAuthAccounts,
  retrieveEvent,
} from '@modules/calendar/store/actions';
import { retrieveClient } from '@modules/profile/store/actions';
import { retrieveTask } from '@modules/task/store/actions';
import Toast from '@shared/common/Toast';
import useSharedHooks from '@shared/hooks/useSharedHooks';
import styles from './styles.module.scss';

momentTimezone.moment = MomentTimezone;

setOptions({
  theme: 'material',
  themeVariant: 'light',
});

const Calendar = () => {
  const {
    toast,
    toggleToast,
    formikRef,
    dispatch,
    isLoading,
    handleCatchErrorToast,
  } = useSharedHooks('calendar');

  const { id } = useParams();
  const taskDetail = useSelector((rootState: RootState) => rootState.task.taskDetail);
  const client = useSelector((rootState: RootState) => rootState.client.user);
  const calendars = useSelector((rootState: RootState) => rootState.calendar.calendars);
  const events = useSelector((rootState: RootState) => rootState.calendar.events);
  const oAuthAccounts = useSelector((rootState: RootState) => rootState.calendar.oAuthAccounts);
  const [selectedCalendar, setSelectedCalendar] = useState<string>('all');

  const integratedAccount = useMemo(() => (
    oAuthAccounts?.find((account) => !account.isDuckbillShared)
  ), [oAuthAccounts]);
  const sharedAccount = useMemo(() => (
    oAuthAccounts?.find((account) => account.isDuckbillShared)
  ), [oAuthAccounts]);
  const [selectedOauthAccount, setSelectedOauthAccount] = useState<string>(integratedAccount?.id || sharedAccount?.id || '');

  const primaryCalendar = useMemo(() => (
    calendars?.find((calendar) => calendar.isPrimary)
  ), [calendars]);
  const [memberTimezone, setMemberTimezone] = useState<string>('America/New_York');
  const [calendarEvents, setCalendarEvents] = useState<MbscCalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<MbscCalendarEvent>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [recurringDeleteOption, setRecurringDeleteOption] = useState<IRecurringDeleteOption>();
  const [deleteConfirmed, setDeleteConfirmed] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [popupEventDate, setPopupEventDate] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [pageDate, setPageDate] = useState({
    start: Moment().startOf('week').toISOString(),
    end: Moment().endOf('week').toISOString(),
  });
  const [scheduleType, setScheduleType] = useState<ICalendarScheduleType>('week');

  const handleFetchCalendars = async () => {
    const response = await dispatch(retrieveOAuthAccounts(client.id));
    const accounts = convertKeysCase(response.payload, 'camelCase') as IOAuthAccount[];
    const integrated = accounts?.find((account) => !account.isDuckbillShared);
    const shared = accounts?.find((account) => account.isDuckbillShared);
    if (shared || integrated) {
      setSelectedOauthAccount((integrated?.id || shared?.id) as string);
      dispatch(retrieveCalendars((integrated?.id || shared?.id) as string));
    } else {
      toggleToast(true, 'No calendar accounts found', 'error');
    }
  };

  useEffect(() => {
    dispatch(retrieveTask(id as string));
  }, []);

  useEffect(() => {
    if (taskDetail) {
      dispatch(retrieveClient(taskDetail.client));
    }
  }, [taskDetail?.client]);

  useEffect(() => {
    if (client?.id) {
      handleFetchCalendars();
    }
    if (client && client.timezone !== 'Unknown') {
      setMemberTimezone(client.timezone);
    }
  }, [client]);

  useEffect(() => {
    if (calendars?.length) {
      dispatch(retrieveEvents({
        start: Moment(pageDate.start).tz(memberTimezone).utc().format(),
        end: Moment(pageDate.end).tz(memberTimezone).utc().format(),
        clientId: client.id,
        taskId: taskDetail.id,
      }));
    }
  }, [calendars?.length, memberTimezone, pageDate, selectedOauthAccount]);

  const getCalendarColor = (calendarId: string) => {
    const cal = calendars.find((calendar) => calendar.id === calendarId);
    return cal?.color || '#D1D1D1';
  };

  const formatEvent = (event: IEvent) => ({
    ...event,
    color: event.isDuckbill ? '#EF7F40' : getCalendarColor(event.calendarId),
    editable: event.isDuckbill,
  });

  useEffect(() => {
    if (events?.length) {
      if (selectedCalendar !== 'all') {
        const formattedEvents = events.reduce((agg: MbscCalendarEvent[], event) => {
          if (event.calendarId === selectedCalendar) {
            return [...agg, formatEvent(event)];
          } else {
            return agg;
          }
        }, []);
        setCalendarEvents(formattedEvents);
      } else {
        const formattedEvents = events.map((event) => (formatEvent(event)));
        setCalendarEvents(formattedEvents);
      }
    } else {
      setCalendarEvents([]);
    }
  }, [events, selectedCalendar]);

  const handleEditMutation = async (
    event: MbscCalendarEvent,
    formikValues?: FormikValues,
    until?: string,
  ) => {
    const formValues = formikValues || formikRef.current?.values;
    const eventId = formValues?.recurringUpdateOption === 'all' ? selectedEvent?.recurringEventId : event.id;
    try {
      await dispatch(updateEvent({
        ...formValues,
        recurrence: getRRule(
          formValues?.recurrence,
          new Date(formValues?.start),
          until,
        ),
        eventId,
        etag: selectedEvent?.etag || event?.etag,
        isDuckbill: true,
        calendarStart: pageDate.start,
        calendarEnd: pageDate.end,
        calendarId: selectedEvent?.calendarId || event?.calendarId,
        new_calendar_id: formValues?.newCalendarId,
        recurringEventId: selectedEvent?.recurringEventId || event?.recurringEventId,
        ...formatAllDayDate(formValues as FormikValues, formValues?.allDay),
        clientId: client.id,
        taskId: taskDetail.id,
      } as updateEventData)).unwrap();
    } catch (e) {
      handleCatchErrorToast(e);
    }
  };

  const handleEditEvent = async (event: MbscCalendarEvent) => {
    const index = calendarEvents.findIndex((calendarEvent) => (
      calendarEvent.id === selectedEvent?.id
    ));
    const newEventList = [...calendarEvents];

    newEventList.splice(index, 1, event);
    setCalendarEvents(newEventList);
    await handleEditMutation(
      event,
      formikRef.current?.values,
      formikRef.current?.values.untilDate
        ? formatUntilDateString(formikRef.current?.values.untilDate, true)
        : undefined,
    );
  };

  const handleSaveEvent = useCallback(async () => {
    const newEvent = {
      id: selectedEvent?.id,
      color: '#EF7F40',
      ...formikRef.current?.values,
    };
    if (isEdit) {
      await handleEditEvent(newEvent);
      setSelectedDate(popupEventDate[0]);
      setIsOpen(false);
    } else {
      try {
        await dispatch(createEvent({
          ...formikRef.current?.values,
          recurrence: getRRule(
            formikRef.current?.values.recurrence,
            new Date(formikRef.current?.values.start),
            formikRef.current?.values.untilDate
              ? formatUntilDateString(formikRef.current?.values.untilDate, true)
              : undefined,
          ),
          isDuckbill: true,
          calendarStart: pageDate.start,
          calendarEnd: pageDate.end,
          calendarId: formikRef.current?.values.newCalendarId,
          ...formatAllDayDate(
            formikRef.current?.values as FormikValues,
            formikRef.current?.values?.allDay,
          ),
          clientId: client.id,
          taskId: taskDetail.id,
        } as createEventData)).unwrap();
        setCalendarEvents([...calendarEvents, newEvent]);
        setSelectedDate(popupEventDate[0]);
        setIsOpen(false);
      } catch (e) {
        handleCatchErrorToast(e);
      }
    }
  }, [
    isEdit,
    calendarEvents,
    selectedEvent,
  ]);

  const handleDeleteEvent = useCallback(async () => {
    if (!selectedEvent || !deleteConfirmed) {
      return;
    }

    if (recurringDeleteOption === 'following') {
      // Update RRULE for the following events to be deleted
      await handleEditMutation(
        selectedEvent,
        { ...formikRef.current?.values },
        formatUntilDateString(selectedEvent?.end as string),
      );
      // If the selected event is the first instance, it needs to be deleted individually.
      // Google Calendar does not delete the first instance by just updating the UNTIL date.
      // Hence, we perform an additional deletion for the first instance.
      await dispatch(deleteEvent({
        eventId: selectedEvent.id as string,
        calendarId: selectedEvent.calendarId as string,
        start: pageDate.start,
        end: pageDate.end,
        clientId: client.id,
        taskId: taskDetail.id,
      }));
    } else {
      await dispatch(deleteEvent({
        eventId: recurringDeleteOption
          ? selectedEvent.recurringEventId
          : (selectedEvent.id as string),
        calendarId: selectedEvent.calendarId as string,
        start: pageDate.start,
        end: pageDate.end,
        clientId: client.id,
        taskId: taskDetail.id,
      }));
    }

    setRecurringDeleteOption(undefined);
    setIsSnackbarOpen(false);
    setDeleteConfirmed(false);
  }, [selectedEvent, pageDate, client, taskDetail, recurringDeleteOption, deleteConfirmed]);

  const snackbarUndoClick = useCallback(() => {
    setDeleteConfirmed(false);
    if (!selectedEvent) {
      return;
    }
    let filteredEvents = calendarEvents.filter((item) => item.id !== selectedEvent.id);
    if (recurringDeleteOption === 'all') {
      filteredEvents = calendarEvents.filter((item) => (
        item.recurringEventId !== selectedEvent.recurringEventId
      ));
    } else if (recurringDeleteOption === 'following') {
      filteredEvents = calendarEvents.filter((item) => (
        (item.recurringEventId !== selectedEvent.recurringEventId)
        || (item.recurringEventId === selectedEvent.recurringEventId
          && new Date(item.start as string) < new Date(selectedEvent.start as string))
      ));
    }
    const recurringEvents = calendarEvents.filter(
      (item) => (recurringDeleteOption === 'all'
        ? item.recurringEventId === selectedEvent.recurringEventId
        : (item.recurringEventId === selectedEvent.recurringEventId
          && new Date(item.start as string) >= new Date(selectedEvent.start as string))),
    );

    setCalendarEvents(recurringDeleteOption
      ? [...filteredEvents, ...recurringEvents]
      : [...filteredEvents, selectedEvent]);
    setRecurringDeleteOption(undefined);
    setIsSnackbarOpen(false);
  }, [selectedEvent, calendarEvents, recurringDeleteOption]);

  const handleLoadEventForm = useCallback((event: MbscCalendarEvent) => {
    setPopupEventDate([event.start as Date, event.end as Date]);
    formikRef.current?.setValues({
      title: event.title,
      description: event.description,
      start: event.start,
      end: event.end,
      allDay: event.allDay,
      location: event.location,
      invitees: event.invitees,
      startTimezone: event.startTimezone || memberTimezone,
      endTimezone: event.endTimezone || memberTimezone,
    });
  }, []);

  const handleDeleteClick = useCallback((updatedRecurringDeleteOption?: IRecurringDeleteOption) => {
    if (selectedEvent) {
      setRecurringDeleteOption(updatedRecurringDeleteOption);
      let filteredEvents = calendarEvents.filter((item) => item.id !== selectedEvent.id);
      if (recurringDeleteOption === 'all') {
        filteredEvents = calendarEvents.filter((item) => (
          item.recurringEventId !== selectedEvent.recurringEventId
        ));
      } else if (recurringDeleteOption === 'following') {
        filteredEvents = calendarEvents.filter((item) => (
          (item.recurringEventId !== selectedEvent.recurringEventId)
          || (item.recurringEventId === selectedEvent.recurringEventId
            && new Date(item.start as string) < new Date(selectedEvent.start as string))
        ));
      }

      setCalendarEvents(filteredEvents);
      setIsSnackbarOpen(true);
      setDeleteConfirmed(true);
    }
    setIsOpen(false);
  }, [calendarEvents, selectedEvent]);

  const handleSelectedDateChange = useCallback((event: MbscCalendarEvent) => {
    setSelectedDate(event.date as Date);
  }, []);

  const handleEventClick = useCallback((args: MbscEventClickEvent) => {
    setIsEdit(true);
    const updatedEvent = { ...args.event };
    if (args.event.allDay) {
      updatedEvent.end = Moment(args.event.end as Date).subtract(1, 'day').format('YYYY-MM-DD');
    }
    setSelectedEvent(updatedEvent);

    handleLoadEventForm(updatedEvent);
    setAnchor(args.domEvent.target);

    if (args.event.editable !== false) {
      setIsOpen(true);
    }
  }, [handleLoadEventForm]);

  const handleEventCreated = useCallback((args: MbscEventCreatedEvent) => {
    setIsEdit(false);
    setSelectedEvent(args.event);

    handleLoadEventForm(args.event);
    setAnchor(args.target);

    setIsOpen(true);
  }, [handleLoadEventForm]);

  const handleClose = useCallback(() => {
    if (!isEdit) {
      setCalendarEvents([...calendarEvents]);
    }
    setIsOpen(false);
  }, [isEdit, calendarEvents]);

  const handleTimeZoneChange = useCallback((ev: { value: string }) => {
    setMemberTimezone(ev.value);
  }, []);

  const handlePageChange = (args: MbscPageChangeEvent) => {
    setPageDate({
      start: args.firstDay.toISOString(),
      end: args.lastDay.toISOString(),
    });
  };

  const handleEventDragEnd = async ({ event }: MbscEventDragEvent) => {
    if (!event.calendarId) {
      return;
    }
    delete event.color;
    delete event.editable;
    try {
      const data = {
        ...event,
        calendarId: event.calendarId as string,
        eventId: event.id as string,
        calendarStart: pageDate.start,
        calendarEnd: pageDate.end,
        taskId: taskDetail.id,
        clientId: taskDetail.client,
        location: event.location || '',
        visibility: event.visibility || 'default',
        allDay: event.allDay || false,
        new_calendar_id: event.calendarId,
      } as updateEventData;
      if (event.recurringEventId) {
        const response = await dispatch(retrieveEvent({
          calendarId: event.calendarId,
          eventId: event.recurringEventId as string,
        }));
        const convertedEvent = convertKeysCase(response.payload, 'camelCase') as IEvent;
        data.recurrence = convertedEvent.recurrence;
      } else {
        data.recurrence = [];
        delete data.recurringEventId;
      }
      await dispatch(updateEvent(data)).unwrap();
    } catch (e) {
      handleCatchErrorToast(e);
    }
  };

  const handleSelectOAuthAccount = (account: string) => {
    setSelectedOauthAccount(account);
    dispatch(retrieveCalendars(account));
  };

  return (
    <div className={`${styles.calendarWrap} ${isLoading ? styles.calendarLoading : ''}`}>
      <Eventcalendar
        displayTimezone={memberTimezone}
        timezonePlugin={momentTimezone}
        view={{ schedule: { type: scheduleType, size: 1 } }}
        data={calendarEvents}
        clickToCreate="single"
        dragToCreate
        dragToMove
        dragToResize
        selectedDate={selectedDate}
        onSelectedDateChange={handleSelectedDateChange}
        onEventClick={handleEventClick}
        onEventCreated={handleEventCreated}
        onPageChange={handlePageChange}
        onEventDragEnd={handleEventDragEnd}
        renderHeader={() => (primaryCalendar ? (
          <CalendarHeader
            calendar={selectedCalendar}
            onSetCalendar={(calendar) => setSelectedCalendar(calendar)}
            calendars={calendars}
            name={client ? `${client.firstName} ${client.lastName}` : ''}
            externalId={primaryCalendar.externalId}
            memberTimezone={memberTimezone}
            onTimeZoneChange={handleTimeZoneChange}
            scheduleType={scheduleType}
            onChangeScheduleType={(e) => e.value && setScheduleType(e.value)}
            selectedOauthAccount={selectedOauthAccount}
            onChangeSelectedOauthAccount={handleSelectOAuthAccount}
            integratedAccount={integratedAccount}
            sharedAccount={sharedAccount}
          />
        ) : null)}
      />
      {primaryCalendar ? (
        <EventForm
          anchor={anchor}
          selectedEvent={selectedEvent}
          isOpen={isOpen}
          onClose={handleClose}
          isEdit={isEdit}
          isLoading={isLoading}
          onSave={handleSaveEvent}
          formikRef={formikRef}
          onDelete={handleDeleteClick}
          popupEventDate={popupEventDate}
          setPopupEventDate={setPopupEventDate}
          calendar={selectedCalendar !== 'all' ? selectedCalendar : primaryCalendar.id}
          calendars={calendars}
          memberTimezone={memberTimezone}
          email={client?.email}
          isSharedCalendar={selectedOauthAccount === sharedAccount?.id}
        />
      ) : null}
      <Toast
        isOpen={toast.isOpen}
        message={toast.message}
        type={toast.type}
        onClose={() => toggleToast(false)}
      />
      <Snackbar
        isOpen={isSnackbarOpen}
        button={{
          action: snackbarUndoClick,
          text: 'Undo',
        }}
        message="Event deleted"
        onClose={handleDeleteEvent}
      />
    </div>
  );
};

export default Calendar;
