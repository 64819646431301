import { createTheme } from '@mui/material/styles';
import { AxiosError, isAxiosError } from 'axios';
import { FormikValues } from 'formik';
import momentTimezone from 'moment-timezone';
import Moment from 'moment/moment';
import {
  IDynamicFieldAnswer,
  IDynamicFieldTemplate,
  IFormField,
  IFormPage,
  IFormSection,
  IRequestError,
} from '@config/models';
import { colors, colorsDark, weekDays } from './constants';

export const getColors = () => {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDark ? colorsDark : colors;
};

export const MUITheme = createTheme({
  palette: {
    primary: {
      main: colors.primaryBlue,
      light: colors.primaryBlue,
      dark: colorsDark.primaryBlue,
    },
    secondary: {
      main: colors.secondaryBlue,
      light: colors.secondaryBlue,
      dark: colorsDark.secondaryBlue,
    },
  },
  typography: {
    fontFamily: 'Manrope',
  },
});

export const matchMediaMock = () => Object.defineProperty(window, 'matchMedia', {
  writable: true,
  value: jest.fn().mockImplementation((query) => ({
    matches: false,
    media: query,
    onchange: null,
    addListener: jest.fn(),
    removeListener: jest.fn(),
    addEventListener: jest.fn(),
    removeEventListener: jest.fn(),
    dispatchEvent: jest.fn(),
  })),
});

const toSnakeCase = (value: string) => value.replace(
  /[A-Z]/g,
  (letter) => `_${letter.toLowerCase()}`,
);

export const toCamelCase = (s: string) => s.replace(
  /([-_][a-z])/ig,
  ($1) => $1.toUpperCase()
    .replace('_', '')
    .replace(' ', ''),
);

const isArray = (value: unknown) => Array.isArray(value);

const isObject = (value: unknown) => (
  value === Object(value)
  && !isArray(value)
  && typeof value !== 'function'
);

export type caseType = 'camelCase' | 'snakeCase';

export const convertKeysCase = (o: unknown, type?: caseType): object => {
  if (isObject(o)) {
    let value = {};
    Object.keys(o as object).forEach((k) => {
      const val = o as { [key: string]: string | object | number | [] };
      value = {
        ...value,
        [type === 'snakeCase' ? toSnakeCase(k) : toCamelCase(k)]: convertKeysCase(val[k], type),
      };
    });
    return value;
  } else if (isArray(o)) {
    const value = o as Array<unknown>;
    return value.map((i) => convertKeysCase(i as object, type));
  }

  return o as object;
};

export const getAnswer = (fieldID: string, answers: IDynamicFieldAnswer[]) => (
  answers.find((answer) => fieldID === answer.field)?.value
);

export const returnError = (error: AxiosError): string => {
  let message = 'Encountered and error, please try again';

  if (!isAxiosError(error)) {
    return JSON.stringify({ message: 'Unknown Server Error', errors: [] });
  }

  const errors = error.response?.data?.errors
    ? convertKeysCase(error.response.data.errors, 'camelCase') as IRequestError[]
    : [];
  if (!error.response && error.message) {
    message = error.message;
  } else if (error.response?.data.message) {
    message = error.response.data.message;
  } else if (error.response?.data.detail) {
    message = error.response.data.detail;
  } else if (error.response?.data.error?.message?.length) {
    message = error.response.data.error.message[0] as string;
  }
  return JSON.stringify({ message, errors });
};

export const getSectionsFields = (sections: IFormSection[]) => sections.reduce((
  agg: IFormField[],
  section: IFormSection,
) => [...agg, ...section.fields], []);

export const getModuleFields = (module: IDynamicFieldTemplate) => module.pages.reduce((
  agg: IFormField[],
  page: IFormPage,
) => [...agg, ...getSectionsFields(page.sections)], []);

export const getFieldFromTemplate = (fieldId: string, template: IDynamicFieldTemplate) => {
  const fields = getModuleFields(template);
  return fields.find((field) => field.id === fieldId);
};

export const getRetoolEnv = () => {
  let env = process.env.REACT_APP_ENV;

  if (env === 'local') {
    env = 'dev';
  } else if (env === 'prod') {
    env = 'production';
  }

  return env;
};

export const getRetoolURL = (taskId: string, zendeskId: string) => {
  const retoolVersion = '2ffca534-cff8-11ee-b0e6-ebb67ac7fd3e';
  const env = getRetoolEnv();

  return `https://retool.getduckbill.com/apps/${retoolVersion}?task_id=${taskId}&zendesk_id=${zendeskId}&_environment=${env}${env === 'dev' ? '&_releaseVersion=latest' : ''}`;
};

export const validateDateRange = (
  startDate: string,
  endDate: string,
  startTimezone: string,
  endTimezone: string,
): string | null => {
  const startMoment = momentTimezone.tz(startDate, startTimezone);
  const endMoment = momentTimezone.tz(endDate, endTimezone);
  if (startMoment.isBefore(endMoment)) {
    return null;
  } else {
    return 'Start date must be before end date.';
  }
};

export const getRRule = (repeat: string, startDate: Date, until?: string) => {
  let rule = '';
  switch (repeat) {
    case 'daily':
      rule = 'RRULE:FREQ=DAILY;';
      break;
    case 'weekly':
      rule = `RRULE:FREQ=WEEKLY;BYDAY=${weekDays[startDate.getDay()].short};`;
      break;
    case 'monthly':
      rule = `RRULE:FREQ=MONTHLY;BYMONTHDAY=${startDate.getDate()};`;
      break;
    case 'yearly':
      rule = `RRULE:FREQ=YEARLY;BYMONTH=${startDate.getMonth() + 1};`;
      break;
    case 'weekday':
      rule = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;';
      break;
    default:
      return [];
  }

  // Append UNTIL if it exists
  if (until) {
    rule += `UNTIL=${until};`;
  }

  return [rule];
};

interface RRuleProperties {
  FREQ?: string;
  BYDAY?: string;
}

export const getRepeatValue = (rruleString: string): string => {
  // Extract the properties from the rruleString line
  const properties: RRuleProperties = rruleString.replace('RRULE:', '').split(';').reduce((agg: RRuleProperties, prop: string) => {
    const [key, value] = prop.split('=');
    agg[key as keyof RRuleProperties] = value;
    return agg;
  }, {});

  // Map the RRULE properties to the repeatData value
  switch (properties.FREQ) {
    case 'DAILY':
      return 'daily';
    case 'WEEKLY':
      // Check if BYDAY is specified and matches weekdays
      if (properties.BYDAY) {
        const days: string[] = properties.BYDAY.split(',');
        if (days.length === 5 && days.every((day) => weekDays.some((wd) => wd.short === day))) {
          return 'weekday';
        }
      }
      return 'weekly';
    case 'MONTHLY':
      return 'monthly';
    case 'YEARLY':
      return 'yearly';
    default:
      return 'norepeat';
  }
};

export const formatAllDayDate = (values: FormikValues, isExclusiveEnd?: boolean) => {
  if (values.allDay) {
    return {
      start: Moment(values.start).format('YYYY-MM-DD'),
      end: Moment(values.end).add(isExclusiveEnd ? 1 : 0, 'day').format('YYYY-MM-DD'),
    };
  } else {
    return {
      start: values.start,
      end: values.end,
    };
  }
};

export const formatUntilDateString = (dateString: string, addOneDay?: boolean) => {
  const originalDate = new Date(dateString);

  // If addOneDay is true, add one day to the original date.
  // This is because when setting a recurring event's end date,
  // the event ends one day before the specified end date. Adding
  // one day ensures the event includes the intended end date.
  if (addOneDay) {
    originalDate.setDate(originalDate.getDate() + 1);
  }

  // Convert to the format accepted by the RRULE UNTIL property 'YYYYMMDDTHHMMSSZ'
  return originalDate.toISOString()
    .replace(/[:-]/g, '')
    .replace(/\.\d{3}Z/, 'Z');
};

export const reloadPage = (error: AxiosError, calendarId?: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasReloaded = urlParams.has('reloaded');
  // Refresh calendar when there is network error or calendarId is not available
  if ((!error.response && error.request) || !calendarId) {
    if (!hasReloaded) {
      urlParams.set('reloaded', 'true');
      window.location.search = urlParams.toString();
    }
  }
};

export const combineDateWithTime = (
  baseDate: Date | undefined,
  timeSource: Date | undefined,
): Date | null => {
  if (!baseDate || !timeSource) return null;

  const baseMoment = Moment(baseDate);
  const timeMoment = Moment(timeSource);

  return timeMoment
    .set({
      year: baseMoment.year(),
      month: baseMoment.month(),
      date: baseMoment.date(),
    })
    .toDate();
};
