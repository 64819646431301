import { createSlice, Draft } from '@reduxjs/toolkit';
import { convertKeysCase } from '@config/helpers';
import {
  IStatusTypes,
  IUser,
} from '@config/models';
import {
  retrieveTask,
} from '@modules/task/store/actions';

export const taskReducer = createSlice({
  name: 'task',
  initialState: {
    isLoading: false,
  } as ITaskState,
  extraReducers: {
    [retrieveTask.pending.type]: (state: ITaskState) => {
      state.isLoading = true;
    },
    [retrieveTask.rejected.type]: (state: ITaskState) => {
      state.isLoading = false;
    },
    [retrieveTask.fulfilled.type]: (state: ITaskState, action) => {
      state.taskDetail = convertKeysCase(action.payload, 'camelCase') as ITaskDetail;
      state.isLoading = false;
    },
  },
  reducers: {
    storeTaskId: (state: Draft<ITaskState>, action) => {
      state.taskId = action.payload;
    },
  },
});

export const { storeTaskId } = taskReducer.actions;
export default taskReducer.reducer;

interface ITaskState {
  isLoading: boolean;
  user: IUser;
  taskDetail: ITaskDetail;
  taskId: string;
}

export interface ITaskDetail {
  id: string;
  zendeskId: string;
  status: IStatusTypes;
  client: string;
}
