import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import {
  TASK_DETAIL_API,
} from '@config/api';
import { returnError } from '@config/helpers';

// eslint-disable-next-line import/prefer-default-export
export const retrieveTask = createAsyncThunk(
  'task/retrieveTask ',
  async (id: string) => {
    try {
      const response = await axios.get(TASK_DETAIL_API(id));
      return response.data;
    } catch (e) {
      throw returnError(e as AxiosError);
    }
  },
);
