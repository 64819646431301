import React, { ChangeEvent } from 'react';
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Select,
  Segmented,
  SegmentedGroup,
} from '@mobiscroll/react';
import timezones from '@assets/json/timezones.json';
import Logo from '@assets/SVGs/icons/Logo';
import { ICalendar, ICalendarScheduleType, IOAuthAccount } from '@config/models';
import styles from './styles.module.scss';

interface Props {
  name: string;
  externalId: string;
  memberTimezone: string;
  onTimeZoneChange: (event: { value: string }) => void;
  calendars: ICalendar[];
  calendar?: string;
  onSetCalendar: (calendar: string) => void;
  scheduleType: ICalendarScheduleType;
  onChangeScheduleType: (selected: { value: ICalendarScheduleType }) => void;
  selectedOauthAccount: string;
  onChangeSelectedOauthAccount: (value: string) => void;
  integratedAccount?: IOAuthAccount;
  sharedAccount?: IOAuthAccount;
}

const CalendarHeader = ({
  name,
  externalId,
  memberTimezone,
  onTimeZoneChange,
  calendars,
  calendar,
  onSetCalendar,
  scheduleType,
  onChangeScheduleType,
  selectedOauthAccount,
  onChangeSelectedOauthAccount,
  sharedAccount,
  integratedAccount,
}: Props) => (
  <div className={styles.calendarHeaderColumn}>
    <div className={styles.calendarHeaderRow}>
      <div className={styles.calendarHeadLeft}>
        <CalendarNav className="md-timezone-nav" />
        <Select
          data={['day', 'week', 'month']}
          inputStyle="box"
          touchUi={false}
          display="anchored"
          value={scheduleType}
          onChange={onChangeScheduleType}
          className={styles.calendarHeaderSelect}
        />
        <div className={styles.calendarHeadLogo}>
          <Logo />
        </div>
        <span className={styles.calendarClientName}>
          {name}
        </span>
        <span className={styles.calendarTaskTitle}>
          {externalId}
        </span>
      </div>
      <div className="md-timezone-header">
        <CalendarPrev className={styles.calendarColor} />
        <CalendarToday className={styles.calendarColor} />
        <CalendarNext className={styles.calendarColor} />
        <Select
          data={timezones}
          inputStyle="box"
          touchUi={false}
          display="anchored"
          value={memberTimezone}
          onChange={onTimeZoneChange}
        />
      </div>
    </div>
    <div className={styles.calendarHeaderListContainer}>
      <div className={styles.calendarHeaderList}>
        {selectedOauthAccount === integratedAccount?.id && (
          <div className={styles.calendarHeaderListItem}>
            <div className={styles.calendarHeaderColor} style={{ backgroundColor: '#EF7F40' }} />
            <span className={styles.calendarHeaderListItemName}>Duckbill event</span>
          </div>
        )}
        {calendars.map((item) => (
          <div className={styles.calendarHeaderListItem} key={item.id}>
            <div className={styles.calendarHeaderColor} style={{ backgroundColor: selectedOauthAccount === sharedAccount?.id ? '#EF7F40' : item.color }} />
            <span className={styles.calendarHeaderListItemName}>{item.name}</span>
          </div>
        ))}
      </div>
      <div className={styles.calendarHeaderOptionsContainer}>
        <div>
          <div className={styles.calendarSegmented}>
            <SegmentedGroup
              select="multiple"
              className={styles.calendarHeaderSegmented}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeSelectedOauthAccount(e.target.value);
              }}
            >
              <Segmented
                value={sharedAccount?.id}
                checked={selectedOauthAccount === sharedAccount?.id}
              >
                Duckbill Calendar
              </Segmented>
              <Segmented
                value={integratedAccount?.id}
                checked={selectedOauthAccount === integratedAccount?.id}
                disabled={!integratedAccount}
              >
                Integrated Calendar
              </Segmented>
            </SegmentedGroup>
          </div>
        </div>
        <Select
          data={[
            { text: 'All calendars', value: 'all' },
            ...calendars.map((cal) => ({ text: cal.name, value: cal.id })),
          ]}
          inputStyle="box"
          touchUi={false}
          display="anchored"
          name="calendar"
          placeholder="Calendar"
          value={calendar}
          onChange={(event) => onSetCalendar(event.value)}
        />
      </div>
    </div>
  </div>
);

export default CalendarHeader;
