import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useMemo,
  useState,
  useCallback,
  KeyboardEvent,
  ChangeEvent,
  useEffect,
} from 'react';
import {
  Button,
  Datepicker,
  Input,
  MbscCalendarEvent,
  MbscPopupButton,
  momentTimezone,
  Popup,
  Select,
  Switch,
  Radio,
  RadioGroup,
} from '@mobiscroll/react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { Formik, FormikProps, FormikValues } from 'formik';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import timezones from '@assets/json/timezones.json';
import { eventVisibilities } from '@config/constants';
import {
  combineDateWithTime,
  convertKeysCase,
  getRepeatValue,
  validateDateRange,
} from '@config/helpers';
import { ICalendar, IEvent, IRecurringDeleteOption } from '@config/models';
import { retrieveEvent } from '@modules/calendar/store/actions';
import Toast from '@shared/common/Toast';
import useSharedHooks from '@shared/hooks/useSharedHooks';
import styles from './styles.module.scss';
import 'react-quill/dist/quill.snow.css';

momentTimezone.moment = MomentTimezone;

const responsivePopup = {
  medium: {
    display: 'anchored',
    width: 400,
    fullScreen: false,
    touchUi: false,
  },
};

const responsiveSelect = {
  xsmall: { touchUi: true },
  small: { touchUi: false },
};

const repeatData = [
  { value: 'norepeat', text: 'Does not repeat' },
  { value: 'daily', text: 'Daily' },
  { value: 'weekly', text: 'Weekly' },
  { value: 'monthly', text: 'Monthly' },
  { value: 'yearly', text: 'Yearly' },
  { value: 'weekday', text: 'Every weekday (Monday to Friday)' },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isEdit: boolean;
  isLoading: boolean;
  onSave: () => void;
  anchor?: HTMLElement;
  selectedEvent?: MbscCalendarEvent;
  formikRef: RefObject<FormikProps<FormikValues>>
  onDelete: (recurringDeleteOption?: IRecurringDeleteOption) => void;
  popupEventDate: Date[];
  setPopupEventDate: Dispatch<SetStateAction<Date[]>>;
  calendar: string;
  calendars: Array<ICalendar>;
  memberTimezone?: string;
  isSharedCalendar: boolean;
  email: string;
}

const EventForm = ({
  isEdit,
  isLoading,
  onSave,
  anchor,
  isOpen,
  onClose,
  selectedEvent,
  formikRef,
  onDelete,
  popupEventDate,
  setPopupEventDate,
  calendar,
  calendars,
  memberTimezone,
  isSharedCalendar,
  email,
}: Props) => {
  const {
    toast,
    toggleToast,
    dispatch,
    handleCatchErrorToast,
  } = useSharedHooks('calendar');
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [start, startRef] = useState<Input | null>(null);
  const [end, endRef] = useState<Input | null>(null);
  const [isInviteesFocused, setIsInviteesFocused] = useState(false);
  const [isEndTimezoneEditable, setIsEndTimezoneEditable] = useState(false);
  const [recurringEventPopup, setRecurringEventPopup] = useState<'update' | 'delete'>();
  const [recurringUpdateOption, setRecurringUpdateOption] = useState<IRecurringDeleteOption>('current');
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [recurringEvent, setRecurringEvent] = useState<IEvent>();

  const handleEventDetail = async () => {
    try {
      setIsEventLoading(true);
      const event = await dispatch(retrieveEvent({
        calendarId: selectedEvent?.calendarId || calendar,
        eventId: selectedEvent?.recurringEventId as string,
      }));
      const convertedEvent = convertKeysCase(event.payload, 'camelCase') as IEvent;
      if (convertedEvent?.recurrence?.length) {
        formikRef.current?.setFieldValue('recurrence', getRepeatValue(convertedEvent.recurrence[0]));
        if (convertedEvent.recurrence[0].includes('UNTIL')) {
          formikRef.current?.setFieldValue('until', 'until');
          formikRef.current?.setFieldValue('untilDate', Moment(convertedEvent.recurrence[0].split(';UNTIL=')[1], 'YYYYMMDDTHHmmssZ').format('YYYY-MM-DD'));
        }
      }
      setRecurringEvent(convertedEvent);
      setIsEventLoading(false);
    } catch (e) {
      handleCatchErrorToast(e);
      setIsEventLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && selectedEvent?.recurringEventId) {
      handleEventDetail();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedEvent) {
      formikRef.current?.setFieldValue('isBusy', selectedEvent?.isBusy);
    }
  }, [selectedEvent]);

  const headerText = useMemo<string>(() => (isEdit ? 'Edit event' : 'New Event'), [isEdit]);

  const onSubmit = async () => {
    if (autoCompleteInputValue.length) {
      await formikRef.current?.setFieldValue(
        'invitees',
        formikRef.current?.values.invitees.length ? [
          ...(formikRef?.current?.values.invitees || []), autoCompleteInputValue,
        ] : [autoCompleteInputValue],
      );
      await setAutoCompleteInputValue('');
    }
    onSave();
  };

  const popupButtons = useMemo(() => {
    if (isEdit) {
      return [
        'cancel',
        {
          handler: () => {
            // Allow updating of recurring events only if the start and end dates are the same
            if (
              selectedEvent?.recurringEventId
              && Moment(selectedEvent.start).isSame(formikRef.current?.values.start, 'day')
              && Moment(selectedEvent.end).isSame(formikRef.current?.values.start, 'day')
            ) {
              setRecurringEventPopup('update');
            } else {
              formikRef.current?.handleSubmit();
            }
          },
          keyCode: isInviteesFocused ? undefined : 'enter',
          text: isLoading ? 'Saving..' : 'Save',
          cssClass: 'mbsc-popup-button-primary',
          disabled: isLoading,
        },
      ];
    } else {
      return [
        'cancel',
        {
          handler: () => {
            formikRef.current?.handleSubmit();
          },
          keyCode: isInviteesFocused ? undefined : 'enter',
          text: isLoading ? 'Adding..' : 'Add',
          cssClass: 'mbsc-popup-button-primary',
          disabled: isLoading,
        },
      ];
    }
  }, [isEdit, onSave, isInviteesFocused, isLoading]);

  const getEndTimezoneLabel = (value: string) => (
    timezones.find((item) => item.value === value)?.text
  );
  // The problem here is, that by default Mobiscroll popup
  // only allows Spaces inside inputs and textareas and Enter only inside textareas.
  // To solve this, we need to listen to the keydown event on the
  // container element of the editor and stop the propagation for Space and Enter keys.
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
    }
  }, []);

  const handleDateChange = (date: Date, type: 'start' | 'end') => {
    if (date) {
      const error = validateDateRange(
        type === 'start' ? date : formikRef?.current?.values.start,
        type === 'end' ? date : formikRef?.current?.values.end,
        formikRef.current?.values.startTimezone,
        formikRef?.current?.values.endTimezone,
      );
      if (error) {
        const startDateValue = new Date(formikRef.current?.values.start);
        const endDateValue = new Date(formikRef.current?.values.end);
        const differenceInMilliseconds = endDateValue.getTime() - startDateValue.getTime();
        const differenceInMinutes = differenceInMilliseconds / 60000;

        const newDate = new Date(date);
        newDate.setMinutes(
          newDate.getMinutes() + (differenceInMinutes > 0 ? differenceInMinutes : 60),
        );
        setPopupEventDate([date, newDate]);
        formikRef.current?.setFieldValue('end', newDate);
      }
      setPopupEventDate(type === 'start' ? [date, popupEventDate[1]] : [popupEventDate[0], date]);
      formikRef.current?.setFieldValue(type, date);
    }
  };

  const handleClose = () => {
    setAutoCompleteInputValue('');
    onClose();
  };

  const handleDelete = () => {
    if (selectedEvent?.recurringEventId) {
      setRecurringEventPopup('delete');
    } else {
      onDelete();
    }
  };

  const handleRecurringOptionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setRecurringUpdateOption(ev.target.value as IRecurringDeleteOption);
  };

  const handleRecurringEventUpdate = () => {
    if (recurringEventPopup === 'delete') {
      onDelete(recurringUpdateOption !== 'current' ? recurringUpdateOption : undefined);
      setRecurringEventPopup(undefined);
    } else {
      if (recurringUpdateOption !== 'current') {
        formikRef.current?.setFieldValue('recurringUpdateOption', recurringUpdateOption);
        const recurringStart = Moment(recurringEvent?.start).toDate();
        const recurringEnd = Moment(recurringEvent?.end).toDate();

        // Combine the recurring event date with the time from the current Formik start/end
        // to update the recurring event without affecting the date
        const updatedStartDate = combineDateWithTime(
          recurringStart,
          formikRef.current?.values.start,
        );
        const updatedEndDate = combineDateWithTime(recurringEnd, formikRef.current?.values.end);

        if (updatedStartDate) formikRef.current?.setFieldValue('start', updatedStartDate);
        if (updatedEndDate) formikRef.current?.setFieldValue('end', updatedEndDate);
      }
      formikRef.current?.handleSubmit();
      setRecurringEventPopup(undefined);
    }
  };

  return (
    <Popup
      display="bottom"
      fullScreen
      contentPadding
      headerText={headerText}
      anchor={anchor}
      buttons={popupButtons as MbscPopupButton[]}
      isOpen={isOpen}
      onClose={handleClose}
      responsiveOptions={responsivePopup}
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          title: selectedEvent?.title || '',
          description: selectedEvent?.description || '',
          start: selectedEvent?.start || popupEventDate[0],
          end: selectedEvent?.end || popupEventDate[1],
          allDay: selectedEvent?.allDay || false,
          isBusy: selectedEvent?.isBusy,
          location: selectedEvent?.location || '',
          invitees: selectedEvent?.invitees || (isSharedCalendar ? [email] : []),
          newCalendarId: selectedEvent?.calendarId || calendar,
          startTimezone: selectedEvent?.startTimezone || memberTimezone,
          endTimezone: selectedEvent?.endTimezone || memberTimezone,
          recurrence: selectedEvent?.recurrence?.length ? getRepeatValue(selectedEvent?.recurrence[0]) : 'norepeat',
          visibility: selectedEvent?.visibility || 'default',
          until: 'never',
          untilDate: null,
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('This field is required'),
          start: Yup.date().required('This field is required'),
          end: Yup.date().required('This field is required'),
        })}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
        }) => (
          <div>
            <div className="mbsc-form-group">
              <Input
                label="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                error={!!(touched.title && errors.title)}
              />
              <div className={styles.eventFormRichTextContainer}>
                <ReactQuill
                  onKeyDown={handleKeyDown}
                  theme="snow"
                  value={values.description}
                  onChange={(value) => setFieldValue('description', value)}
                  placeholder="Description"
                />
              </div>
            </div>
            <div className="mbsc-form-group">
              <Switch
                label="All-day"
                checked={values.allDay}
                onChange={() => setFieldValue('allDay', !values.allDay)}
              />
              <Switch
                label="Busy"
                checked={values.isBusy}
                onChange={() => setFieldValue('isBusy', !values.isBusy)}
              />
              <Input
                ref={startRef}
                label="Starts"
                error={!!(touched.start && errors.start)}
              />
              <Datepicker
                displayTimezone={values.startTimezone}
                dataTimezone={values.startTimezone}
                timezonePlugin={momentTimezone}
                select="range"
                controls={values.allDay ? ['date'] : ['datetime']}
                touchUi
                startInput={start}
                showRangeLabels={false}
                responsive={{
                  medium: {
                    controls: values.allDay ? ['calendar'] : ['calendar', 'time'],
                    touchUi: false,
                  },
                }}
                onChange={(args: { value: Date[] }) => {
                  if (args.value[0] !== values.start) {
                    handleDateChange(args.value[0], 'start');
                  }
                }}
                value={values.start}
              />
              <Select
                label="Start date timezone"
                data={timezones}
                inputStyle="box"
                touchUi={false}
                display="anchored"
                name="startTimezone"
                placeholder="Timezone"
                value={values.startTimezone}
                onChange={(event) => {
                  setFieldValue('startTimezone', event.value);
                  if (!isEndTimezoneEditable) {
                    setFieldValue('endTimezone', event.value);
                  }
                }}
              />
              <Input
                ref={endRef}
                label="Ends"
                error={!!(touched.end && errors.end)}
              />
              {!isEndTimezoneEditable && (
                <div className={styles.eventFormEditTimezoneContainer}>
                  <p>{getEndTimezoneLabel(values.endTimezone)}</p>
                  <Button
                    color="primary"
                    className={styles.eventFormEditTimezoneButton}
                    onClick={() => setIsEndTimezoneEditable(true)}
                  >
                    Edit end timezone
                  </Button>
                </div>
              )}
              {isEndTimezoneEditable && (
                <Select
                  label="End date timezone"
                  data={timezones}
                  inputStyle="box"
                  touchUi={false}
                  display="anchored"
                  name="endTimezone"
                  placeholder="Timezone"
                  value={values.endTimezone}
                  onChange={(event) => setFieldValue('endTimezone', event.value)}
                />
              )}
              <Datepicker
                displayTimezone={values.endTimezone}
                dataTimezone={values.endTimezone}
                timezonePlugin={momentTimezone}
                select="range"
                controls={values.allDay ? ['date'] : ['datetime']}
                touchUi
                startInput={end}
                showRangeLabels={false}
                responsive={{
                  medium: {
                    controls: values.allDay ? ['calendar'] : ['calendar', 'time'],
                    touchUi: false,
                  },
                }}
                onChange={(args: { value: Date[] }) => {
                  if (args.value[0] !== values.end) {
                    handleDateChange(args.value[0], 'end');
                  }
                }}
                value={values.end}
              />
            </div>
            <div className="mbsc-form-group">
              <Select
                data={calendars.filter((cal) => ['writer', 'owner'].includes(cal.accessRole)).map((cal) => ({ text: cal.name, value: cal.id }))}
                inputStyle="box"
                touchUi={false}
                display="anchored"
                name="newCalendarId"
                placeholder="Calendar"
                value={values.newCalendarId}
                onChange={(event) => setFieldValue('newCalendarId', event.value)}
              />
              <Input
                label="Location"
                name="location"
                value={values.location}
                onChange={handleChange}
                error={!!(touched.location && errors.location)}
              />
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                className={styles.eventFormInvitees}
                value={values.invitees}
                freeSolo
                inputValue={autoCompleteInputValue}
                onInputChange={(event, newValue) => setAutoCompleteInputValue(newValue)}
                onChange={(event, value) => setFieldValue('invitees', value)}
                onFocus={() => setIsInviteesFocused(true)}
                onBlur={() => setIsInviteesFocused(false)}
                renderTags={(value: readonly string[], getTagProps) => (
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Invitees"
                    placeholder="Invitees"
                    type="email"
                  />
                )}
              />
              {isSharedCalendar && (
                <span className={styles.eventFormWhisper}>
                  Client email is compulsory to be part of the invitees
                  {' '}
                  list for Duckbill shared calendar
                </span>
              )}
              <Select
                data={repeatData}
                label="Repeats"
                value={values.recurrence}
                responsive={responsiveSelect}
                onChange={(event) => setFieldValue('recurrence', event.value)}
                disabled={isEventLoading}
              />
              {values.recurrence !== 'norepeat' && (
                <>
                  <div className={styles.recurrenceEnds}>Ends</div>
                  <div className="mbsc-form-group">
                    <RadioGroup>
                      <Radio
                        label="Never"
                        position="start"
                        description="The event will repeat indefinitely"
                        checked={values.until === 'never'}
                        onChange={(e: { target: { value?: string; } }) => {
                          setFieldValue('until', e.target.value);
                          setFieldValue('untilDate', null);
                        }}
                        value="never"
                      />

                      <Radio
                        checked={values.until === 'until'}
                        onChange={(e: { target: { value?: string; } }) => setFieldValue('until', e.target.value)}
                        value="until"
                        position="start"
                      >
                        Until
                        <span className="md-recurrence-input">
                          <Datepicker
                            inputStyle="box"
                            controls={['calendar']}
                            display="anchored"
                            touchUi={false}
                            dateFormat="YYYY-MM-DD"
                            returnFormat="iso8601"
                            value={values.untilDate}
                            placeholder="Until date"
                            onChange={(e: { value: string }) => setFieldValue('untilDate', e.value)}
                            onOpen={() => setFieldValue('until', 'until')}
                          />
                        </span>
                        <span className="mbsc-description">The event will run until it reaches a specific date</span>
                      </Radio>
                    </RadioGroup>
                  </div>
                </>
              )}
              <Select
                label="Visibility"
                data={eventVisibilities}
                inputStyle="box"
                touchUi={false}
                display="anchored"
                name="visibility"
                placeholder="Visibility"
                value={values.visibility}
                onChange={(event) => setFieldValue('visibility', event.value)}
              />
            </div>
            {isEdit && (
              <div className={styles.eventFormDeleteWrap}>
                <Button
                  className="mbsc-button-block"
                  color="danger"
                  variant="outline"
                  onClick={handleDelete}
                >
                  Delete event
                </Button>
              </div>
            )}
          </div>
        )}
      </Formik>
      <Popup
        display="bottom"
        contentPadding={false}
        buttons={[
          'cancel',
          {
            handler: handleRecurringEventUpdate,
            keyCode: 'enter',
            text: recurringEventPopup === 'delete' ? 'Delete' : 'Update',
            cssClass: 'mbsc-popup-button-primary',
          },
        ]}
        isOpen={!!recurringEventPopup}
        onClose={() => setRecurringEventPopup(undefined)}
      >
        <div className="mbsc-form-group">
          <div className="mbsc-form-group-title">
            {recurringEventPopup === 'delete' ? 'Delete' : 'update'}
            {' '}
            recurring event
          </div>
          <RadioGroup onChange={handleRecurringOptionChange}>
            <Radio
              label="This event only"
              checked={recurringUpdateOption === 'current'}
              onChange={handleRecurringOptionChange}
              value="current"
            />
            {/* TODO: (Abdul) Update this and following event */}
            {recurringEventPopup === 'delete' ? (
              <Radio
                label="This and following events"
                checked={recurringUpdateOption === 'following'}
                onChange={handleRecurringOptionChange}
                value="following"
              />
            ) : null}
            <Radio
              label="All events"
              checked={recurringUpdateOption === 'all'}
              onChange={handleRecurringOptionChange}
              value="all"
            />
          </RadioGroup>
        </div>
      </Popup>
      <Toast
        isOpen={toast.isOpen}
        message={toast.message}
        type={toast.type}
        onClose={() => toggleToast(false)}
      />
    </Popup>
  );
};

export default EventForm;
