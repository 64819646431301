import { convertKeysCase } from '@config/helpers';

declare global {
  interface Window {
    analytics?: {
      identify: (id: string, data: {[key: string]: string}) => void;
      track: (event: string, data: {[key: string]: string}) => void;
      ready: (callback: () => void) => void;
      user: () => ({
        anonymousId: (id?: string) => string;
      });
    },
  }
}

export interface ISegmentUserData {
  id: string;
  externalId: string;
  name: string;
  email: string;
  organization: {
    id: string;
  };
}

export const identify = (user: ISegmentUserData) => {
  const {
    id,
    name,
    email,
  } = user;
  window.analytics?.identify(id, convertKeysCase({
    agentId: id,
    name,
    email,
  }, 'snakeCase') as {[key: string]: string});
};

export const track = (
  object: string,
  action: string,
  data: {[key: string]: string | number},
  user?: ISegmentUserData,
) => {
  const userData = user ? {
    agentId: user.id,
  } : {};
  window.analytics?.track(`${object} ${action}`, {
    ...convertKeysCase(userData, 'snakeCase'),
    ...convertKeysCase(data, 'snakeCase'),
    object,
    action,
  });
};
