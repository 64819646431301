import { createSlice } from '@reduxjs/toolkit';
import { calendarColors } from '@config/constants';
import { convertKeysCase } from '@config/helpers';
import { ICalendar, IEvent, IOAuthAccount } from '@config/models';
import {
  retrieveOAuthAccounts,
  retrieveCalendars,
  retrieveEvents,
  deleteEvent,
  updateEvent,
  createEvent,
} from './actions';

const calendarReducer = createSlice({
  name: 'calendar',
  initialState: {
    isLoading: false,
  } as ICalendarState,
  extraReducers: {
    [retrieveOAuthAccounts.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [retrieveOAuthAccounts.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [retrieveOAuthAccounts.fulfilled.type]: (state: ICalendarState, action) => {
      state.oAuthAccounts = convertKeysCase(action.payload, 'camelCase') as IOAuthAccount[];
      state.isLoading = false;
    },
    [retrieveCalendars.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [retrieveCalendars.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [retrieveCalendars.fulfilled.type]: (state: ICalendarState, action) => {
      const filteredCalendars = action.payload.filter((calendar: ICalendar) => !calendar.name.startsWith('Transferred from'));
      const calendars = convertKeysCase(filteredCalendars, 'camelCase') as ICalendar[];
      state.calendars = calendars.map((item, index) => {
        if (index > calendarColors.length - 1) {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          return { ...item, color: `#${randomColor}` };
        } else {
          return { ...item, color: calendarColors[index] };
        }
      });
      state.isLoading = false;
    },
    [retrieveEvents.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [retrieveEvents.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [retrieveEvents.fulfilled.type]: (state: ICalendarState, action) => {
      state.events = convertKeysCase(action.payload, 'camelCase') as IEvent[];
      state.isLoading = false;
    },
    [createEvent.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [createEvent.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [createEvent.fulfilled.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [updateEvent.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [updateEvent.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [updateEvent.fulfilled.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [deleteEvent.pending.type]: (state: ICalendarState) => {
      state.isLoading = true;
    },
    [deleteEvent.rejected.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
    [deleteEvent.fulfilled.type]: (state: ICalendarState) => {
      state.isLoading = false;
    },
  },
  reducers: {},
});

interface ICalendarState {
  isLoading: boolean;
  oAuthAccounts: IOAuthAccount[];
  calendars: ICalendar[];
  events: IEvent[];
}

export default calendarReducer.reducer;
