export const TASKS_API = 'tasks/v2/';
export const TASK_DETAIL_API = (id: string) => `${TASKS_API}${id}/`;
export const USER_API = 'clients/';
export const USER_DETAIL_API = (id: string) => `${USER_API}${id}/`;
export const RETRIEVE_TOKEN_API = 'agents/retrieve/';
export const RETRIEVE_OAUTH_ACCOUNTS_API = (id: string) => `oauth/?purpose=calendar&client_id=${id}`;
export const RETRIEVE_CALENDARS_API = (id: string) => `calendars/?account_id=${id}&is_active=true`;
export const EVENTS_API = (id: string, clientId: string, taskId?: string) => `calendars/${id}/events/?client_id=${clientId}&task_id=${taskId}`;
export const EVENT_API = (id: string, eventId: string) => `calendars/${id}/events/${eventId}`;
export const RETRIEVE_EVENTS_API = (id: string, clientId: string, taskId?: string, start?: string, end?: string) => `calendars/${id}/events/?start=${start}&end=${end}&client_id=${clientId}&task_id=${taskId}`;
export const UPDATE_EVENT_API = (calendarId: string, eventId: string, clientId: string, taskId?: string) => `calendars/${calendarId}/events/${eventId}/?client_id=${clientId}&task_id=${taskId}`;
