import React from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import RouteContainer from '@config/routes/route-container';
import { RootState } from '@config/store';
import WrongOrganization from '@modules/auth/pages/WrongOrganization';
import Calendar from '@modules/calendar/pages/Calendar';
import TaskDetail from '@modules/task/pages/TaskDetail';

const AppRoutes = () => {
  const taskDetail = useSelector((state: RootState) => state.task.taskDetail);
  const client = useSelector((state: RootState) => state.client.user);

  const standaloneRoutes = [
    {
      path: 'tasks/:id/calendar',
      element: <RouteContainer element={<Calendar />} isPublic />,
    },
  ];

  const sidebarRoutes = [
    {
      path: 'wrong-organization',
      element: <RouteContainer element={<WrongOrganization />} isPublic />,
    },
    {
      path: 'tasks',
      children: [
        {
          path: ':id',
          children: [
            {
              path: '',
              element: <RouteContainer element={<TaskDetail />} isPublic />,
            },
          ],
        },
      ],
    },
  ];

  if (taskDetail || client) {
    return useRoutes([...standaloneRoutes, ...sidebarRoutes]);
  } else {
    return useRoutes(standaloneRoutes);
  }
};

export default AppRoutes;
