import colorMap from '../colors.scss';
import '../index.scss';

export const colors = Object.entries(colorMap).reduce((agg, [key, val]) => {
  if (!key.endsWith('Dark')) {
    agg[key] = val;
  }
  return agg;
}, {} as { [key: string]: unknown }) as { [key: string]: string };

export const colorsDark = Object.entries(colorMap).reduce((agg, [key, val]) => {
  if (key.endsWith('Dark')) {
    agg[key.replace('Dark', '')] = val;
  }
  return agg;
}, {} as { [key: string]: unknown }) as { [key: string]: string };

export const calendarColors = [
  '#8EE3E9',
  '#D9BFEA',
  '#F4AF99',
  '#E8D594',
  '#E9A7BE',
  '#2A6341',
  '#EEFB86',
  '#486075',
  '#B2BEB5',
  '#6082B6',
  '#8A9A5B',
  '#36454F',
  '#A9A9A9',
  '#7393B3',
];

export const weekDays = [
  { name: 'Sunday', short: 'SU' },
  { name: 'Monday', short: 'MO' },
  { name: 'Tuesday', short: 'TU' },
  { name: 'Wednesday', short: 'WE' },
  { name: 'Thursday', short: 'TH' },
  { name: 'Friday', short: 'FR' },
  { name: 'Saturday', short: 'SA' },
];

export const eventVisibilities = [
  { text: 'Default visibility', value: 'default' },
  { text: 'Public', value: 'public' },
  { text: 'Private', value: 'private' },
  { text: 'Confidential', value: 'confidential' },
];
