import * as React from 'react';
import { AlertColor } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import MUISnackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const Snackbar = styled(MUISnackbar)({
  position: 'absolute',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
});

interface Props {
  isOpen: boolean;
  message: string;
  type: AlertColor;
  onClose: () => void;
}

const Toast = ({
  isOpen,
  message,
  type,
  onClose,
}: Props) => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
      >
        <div className={styles.toastContent}>
          {message}
        </div>
      </Alert>
    </Snackbar>
  );
};

export default Toast;
