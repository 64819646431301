import { createSlice, Draft } from '@reduxjs/toolkit';

export const authReducer = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
  } as IAuthState,
  extraReducers: {},
  reducers: {
    storeToken: (state: Draft<IAuthState>, action) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
    },
  },
});

export const { storeToken } = authReducer.actions;
export default authReducer.reducer;

export interface IAuthState {
  isLoading: boolean;
  email: string;
  token: string;
}
