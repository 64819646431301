import React from 'react';
import QuickLinks from '@modules/task/components/QuickLinks';
import Wrapper from '@shared/common/Wrapper';

const TaskDetail = () => (
  <Wrapper>
    <QuickLinks />
  </Wrapper>
);

export default TaskDetail;
