import { createSlice, Draft } from '@reduxjs/toolkit';
import { convertKeysCase } from '@config/helpers';
import { IUser } from '@config/models';
import {
  retrieveClient,
} from '@modules/profile/store/actions';

const clientReducer = createSlice({
  name: 'client',
  initialState: {
    isLoading: false,
  } as IClientState,
  extraReducers: {
    [retrieveClient.pending.type]: (state: IClientState) => {
      state.isLoading = true;
    },
    [retrieveClient.rejected.type]: (state: IClientState) => {
      state.isLoading = false;
    },
    [retrieveClient.fulfilled.type]: (state: IClientState, action) => {
      state.user = convertKeysCase(action.payload, 'camelCase') as IUser;
      state.isLoading = false;
    },
  },
  reducers: {
    storeClientId: (state: Draft<IClientState>, action) => {
      state.clientId = action.payload;
    },
  },
});

interface IClientState {
  isLoading: boolean;
  user: IUser;
  clientId: string;
}

export const { storeClientId } = clientReducer.actions;
export default clientReducer.reducer;
