import { useRef, useState } from 'react';
import { AlertColor } from '@mui/material';
import { FormikProps, FormikValues } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toCamelCase, getFieldFromTemplate } from '@config/helpers';
import { IRequestErrorResponse, IDynamicFieldTemplate } from '@config/models';
import { RootState, useAppDispatch, IReducerNames } from '@config/store';

const useSharedHooks = (stateName?: IReducerNames) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const isLoading = useSelector((state: RootState) => state[stateName || 'shared'].isLoading);
  const [toast, setToast] = useState({
    message: '',
    type: 'success' as AlertColor,
    isOpen: false,
  });

  const toggleToast = (isOpen: boolean, message?: string, type?: AlertColor) => {
    setToast({
      isOpen,
      message: message || '',
      type: type || 'success',
    });
  };

  const setFieldErrors = (
    errorResponse: IRequestErrorResponse,
    template?: IDynamicFieldTemplate,
  ) => {
    let toastMessage = `${errorResponse.message}`;
    errorResponse.errors.forEach((error) => {
      formikRef.current?.setFieldError(toCamelCase(error.field), error.message);
      if (template) {
        toastMessage = `${toastMessage}\n${getFieldFromTemplate(error.field, template)?.label}: ${error.message}`;
      }
    });
    toggleToast(true, toastMessage, 'error');
  };

  const handleCatchErrorToast = async (e: unknown, template?: IDynamicFieldTemplate) => {
    const response = e as { message: string };
    try {
      const error = JSON.parse(response.message) as IRequestErrorResponse;
      toggleToast(true, error.message, 'error');
      if (formikRef && error.errors.length) {
        setFieldErrors(error, template);
      }
    } catch (_e) {
      toggleToast(true, response.message, 'error');
    }
  };

  return {
    dispatch,
    isLoading,
    handleCatchErrorToast,
    navigate,
    formikRef,
    toast,
    toggleToast,
  };
};

export default useSharedHooks;
