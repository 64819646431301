import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const toastReducer = createSlice({
  name: 'shared',
  initialState: {
    isLoading: false,
    toast: { message: '', isOpen: false, type: 'info' },
  } as ISharedState,
  extraReducers: {},
  reducers: {
    showToast: (state, action: PayloadAction<IToast>) => {
      state.toast = action.payload;
    },
  },
});

export const { showToast } = toastReducer.actions;
export default toastReducer.reducer;

interface ISharedState {
  isLoading: boolean;
  toast: IToast;
}

export interface IToast {
  message: string;
  isOpen: boolean;
  type?: AlertColor;
}
