import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '@config/store';

interface Props {
  element: JSX.Element;
  isPublic: boolean;
}

type ILocationState = {
  from: string;
}

const RouteContainer = ({ element, isPublic }: Props) => {
  const user = useSelector((state: RootState) => state.task.user);
  const { pathname, state } = useLocation();
  const { from = '/' } = state as ILocationState || {};

  if (isPublic) {
    return user && from !== '/' ? <Navigate to={from} /> : element;
  } else {
    return user ? element : <Navigate state={{ from: pathname }} to="/log-in" />;
  }
};

export default RouteContainer;
