import React, { useEffect, useMemo, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Client, ZAFClientContextProvider } from '@zendesk/sell-zaf-app-toolbox';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MUITheme } from '@config/helpers';
import App from './App';
import store from './config/store';
import reportWebVitals from './reportWebVitals';
import './index.scss';

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

declare const ZAFClient: {
  init: () => Client
};

const container = document.getElementById('root');
const root = createRoot(container as Element);

const Wrap = () => {
  const client = useMemo(() => ZAFClient.init(), []);

  const ref = useRef(null);
  const observer = useRef(new ResizeObserver((entries) => {
    const target = entries[0].target as HTMLElement;
    const { offsetHeight } = target;
    const height = `${offsetHeight}px`;
    if (client) {
      client.invoke('resize', { height });
    }
  }));

  useEffect(() => {
    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, observer]);

  return (
    <div ref={ref}>
      <ZAFClientContextProvider value={client}>
        <ThemeProvider theme={MUITheme}>
          <React.StrictMode>
            <Provider store={store}>
              <Router>
                <App />
              </Router>
            </Provider>
          </React.StrictMode>
        </ThemeProvider>
      </ZAFClientContextProvider>
    </div>
  );
};

root.render(<Wrap />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
