import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import {
  EVENT_API,
  EVENTS_API,
  RETRIEVE_CALENDARS_API,
  RETRIEVE_EVENTS_API,
  RETRIEVE_OAUTH_ACCOUNTS_API,
  UPDATE_EVENT_API,
} from '@config/api';
import { reloadPage, returnError } from '@config/helpers';
import { IEvent } from '@config/models';
import type { RootState } from '@config/store';

export const retrieveOAuthAccounts = createAsyncThunk(
  'calendar/retrieveOAuthAccounts',
  async (id: string) => {
    try {
      const response = await axios.get(RETRIEVE_OAUTH_ACCOUNTS_API(id));
      return response.data;
    } catch (e) {
      throw returnError(e as AxiosError);
    }
  },
);

export const retrieveCalendars = createAsyncThunk(
  'calendar/retrieveCalendars',
  async (id: string) => {
    try {
      const response = await axios.get(RETRIEVE_CALENDARS_API(id));
      return response.data;
    } catch (e) {
      throw returnError(e as AxiosError);
    }
  },
);

interface retrieveCalendarEventsData {
  id: string;
  start?: string;
  end?: string;
  clientId: string;
  taskId?: string;
}

export const retrieveCalendarEvents = createAsyncThunk(
  'calendar/retrieveCalendarEvents',
  async ({
    id,
    start,
    end,
    clientId,
    taskId,
  }: retrieveCalendarEventsData) => {
    try {
      const response = await axios.get(RETRIEVE_EVENTS_API(id, clientId, taskId, start, end));
      if (response.data.length) {
        return response.data.map((item: IEvent) => ({ ...item, calendarId: id }));
      } else {
        return [];
      }
    } catch (e) {
      throw returnError(e as AxiosError);
    }
  },
);

interface retrieveEventsData {
  start?: string;
  end?: string;
  clientId: string;
  taskId?: string;
}

export const retrieveEvents = createAsyncThunk(
  'calendar/retrieveEvents',
  async ({
    start, end, clientId, taskId,
  }: retrieveEventsData, { getState, dispatch }) => {
    const fetchEvents = async (id: string): Promise<IEvent[]> => {
      const response = await dispatch(retrieveCalendarEvents({
        id, start, end, clientId, taskId,
      }));
      return response.payload.length
        ? response.payload.map((event: IEvent) => ({ ...event, calendarId: id }))
        : [];
    };

    const { calendar } = getState() as RootState;
    let events: IEvent[] = [];

    const promises: Promise<IEvent[]>[] = [];
    for (let i = 0; i < calendar.calendars.length; i += 1) {
      promises.push(fetchEvents(calendar.calendars[i].id));
    }
    const calendarEvents = await Promise.all(promises);
    calendarEvents.forEach((event) => {
      events = [...events, ...event];
    });
    return events;
  },
);

interface retrieveEventData {
  calendarId: string;
  eventId: string;
}

export const retrieveEvent = createAsyncThunk(
  'calendar/retrieveEvent',
  async ({ calendarId, eventId }: retrieveEventData) => {
    try {
      const response = await axios.get(EVENT_API(calendarId, eventId));
      return response.data;
    } catch (e) {
      throw returnError(e as AxiosError);
    }
  },
);

export interface createEventData {
  calendarId: string;
  title: string;
  description?: string;
  start: string;
  end: string;
  location?: string;
  allDay: boolean;
  invitees?: string;
  isDuckbill: boolean;
  startTimezone?: string;
  endTimezone?: string;
  calendarStart: string;
  calendarEnd: string;
  recurrence?: string[];
  visibility?: string;
  clientId: string;
  taskId?: string;
}

export const createEvent = createAsyncThunk(
  'calendar/createEvent',
  async ({
    calendarId,
    calendarStart,
    calendarEnd,
    clientId,
    taskId,
    ...rest
  }: createEventData, { dispatch }) => {
    try {
      const response = await axios.post(EVENTS_API(calendarId, clientId, taskId), rest);
      await dispatch(retrieveEvents({
        start: calendarStart,
        end: calendarEnd,
        clientId,
        taskId,
      }));
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      reloadPage(error, calendarId);
      await dispatch(retrieveEvents({
        start: calendarStart,
        end: calendarEnd,
        clientId,
        taskId,
      }));
      throw returnError(e as AxiosError);
    }
  },
);

export interface updateEventData extends createEventData {
  etag: string;
  eventId: string;
  calendarStart: string;
  calendarEnd: string;
  calendar: string;
  new_calendar_id: string;
  recurringEventId?: string;
  visibility?: string;
  clientId: string;
  taskId?: string;
}

export const updateEvent = createAsyncThunk(
  'calendar/createEvent',
  async ({
    calendarId,
    eventId,
    calendarEnd,
    calendarStart,
    clientId,
    taskId,
    ...rest
  }: updateEventData, { dispatch }) => {
    try {
      const response = await axios.put(
        UPDATE_EVENT_API(calendarId, eventId, clientId, taskId),
        rest,
      );
      await dispatch(retrieveEvents({
        start: calendarStart,
        end: calendarEnd,
        clientId,
        taskId,
      }));
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      reloadPage(error, calendarId);
      await dispatch(retrieveEvents({
        start: calendarStart,
        end: calendarEnd,
        clientId,
        taskId,
      }));
      throw returnError(e as AxiosError);
    }
  },
);

export interface deleteEventData {
  calendarId: string;
  eventId: string;
  start: string;
  end: string;
  clientId: string;
  taskId?: string;
}

export const deleteEvent = createAsyncThunk(
  'calendar/createEvent',
  async ({
    calendarId,
    eventId,
    start,
    end,
    clientId,
    taskId,
  }: deleteEventData, { dispatch }) => {
    try {
      const response = await axios.delete(UPDATE_EVENT_API(calendarId, eventId, clientId, taskId));
      await dispatch(retrieveEvents({
        start,
        end,
        clientId,
        taskId,
      }));
      return response.data;
    } catch (e) {
      await dispatch(retrieveEvents({
        start,
        end,
        clientId,
        taskId,
      }));
      throw returnError(e as AxiosError);
    }
  },
);
