import React from 'react';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
}
const Wrapper = ({ children }: Props) => (
  <section className={`animate__animated animate__fadeIn ${styles.wrapperWrap}`}>
    {children}
  </section>
);

export default Wrapper;
