import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from '@modules/auth/store/reducer';
import calendar from '@modules/calendar/store/reducer';
import client from '@modules/profile/store/reducer';
import task from '@modules/task/store/reducer';
import shared from '@shared/store/reducer';

const reducers = combineReducers({
  task,
  shared,
  client,
  auth,
  calendar,
});

export type IReducerNames = (
  'task' |
  'shared' |
  'client' |
  'auth' |
  'calendar'
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

interface ITestReduxProvider {
  children: React.ReactNode;
}
export const TestReduxProvider = ({ children }: ITestReduxProvider) => (
  <Provider store={store}>{children}</Provider>
);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const persistor = persistStore(store);
