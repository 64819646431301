import React from 'react';
import { Button } from '@mobiscroll/react';
import { useSelector } from 'react-redux';
import LinkIcon from '@assets/SVGs/icons/LinkIcon';
import { getRetoolEnv, getRetoolURL } from '@config/helpers';
import { RootState } from '@config/store';
import styles from './styles.module.scss';

interface QuickLinkItemProps {
  icon?: React.ReactNode;
  link: string;
  text: string;
}

const QuickLinkItem = ({
  icon,
  link,
  text,
}: QuickLinkItemProps) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className={styles.quickLinkItem}
  >
    {icon ? (
      <div className={styles.quickLinkItemIconWrap}>
        {icon}
      </div>
    ) : null}
    <span className={styles.quickLinkItemText}>{text}</span>
    <LinkIcon />
  </a>
);

const authChromeExtension = (taskId: string, token: string, email: string) => {
  const event = new CustomEvent(
    'copilotAppAuth',
    {
      detail: {
        email,
        token,
        taskId,
        env: getRetoolEnv(),
      },
    },
  );

  window.dispatchEvent(event);
};

const QuickLinks = () => {
  const taskDetail = useSelector((state: RootState) => state.task.taskDetail);
  const auth = useSelector((state: RootState) => state.auth);
  const cockpitUrl = getRetoolURL(taskDetail.id, taskDetail.zendeskId);
  const onClick = () => {
    authChromeExtension(taskDetail.id, auth.token, auth.email);
    window.open(cockpitUrl, '_blank');
  };

  return (
    <section className={styles.quickLinksWrap}>
      <p className={styles.quickLinksTitle}>
        Quick Links
      </p>
      <div className={styles.quickLinksItemsWrap}>
        <Button
          color="primary"
          onClick={onClick}
        >
          Start working on task
        </Button>
        <QuickLinkItem
          link={cockpitUrl}
          text="💻 Cockpit"
        />
      </div>
    </section>
  );
};

export default QuickLinks;
