import axios from 'axios';
import { RETRIEVE_TOKEN_API } from '@config/api';
import store, { persistor } from '@config/store';

if (process.env.REACT_APP_API_URL !== process.env.REACT_APP_API_URL?.trim()) {
  // eslint-disable-next-line
  console.warn('REACT_APP_API_URL has whitespace, check your env vars');
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    // [AS 2023-02-03] Special case for RETRIEVE_TOKEN_API since it uses a non-user
    // token (either supplied locally or injected by the Zendesk proxy)
    if (config.url === RETRIEVE_TOKEN_API) {
      return config;
    }
    const { token } = store.getState().auth;
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
);

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    // [AS 2023-01-11] This is a little brute force - ideally we'd refresh the token,
    // but since this only seems to happen locally, nuking the storage seems reasonable
    persistor.purge();
  }
  return Promise.reject(error);
});
