import React, { useEffect } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useClientGet, useClientRequestWithAuth } from '@zendesk/sell-zaf-app-toolbox';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { RETRIEVE_TOKEN_API } from '@config/api';
import { convertKeysCase } from '@config/helpers';
import { ICurrentUserData } from '@config/models';
import AppRoutes from '@config/routes/index';
import { identify, ISegmentUserData, track } from '@config/segment';
import { persistor, RootState } from '@config/store';
import { storeToken } from '@modules/auth/store/reducer';
import { retrieveClient } from '@modules/profile/store/actions';
import { storeClientId } from '@modules/profile/store/reducer';
import { retrieveTask } from '@modules/task/store/actions';
import { storeTaskId } from '@modules/task/store/reducer';
import Loader from '@shared/common/Loader';
import useSharedHooks from '@shared/hooks/useSharedHooks';
import '@config/client';
import './index.scss';

interface ITaskData {
  externalId: string;
  organization: {
    id: string;
  };
}
interface IClientData {
  externalId: string;
  organizations: {
    id: string;
  }[];
}

const App = () => {
  const { dispatch, handleCatchErrorToast, navigate } = useSharedHooks();
  const token = useSelector((state: RootState) => state.auth.token);
  const userData = useClientGet<ICurrentUserData>('currentUser').data;
  const taskData = useClientGet<ITaskData>('ticket').data;
  const clientData = useClientGet<IClientData>('user').data;
  const [searchParams, setSearchParam] = useSearchParams();

  useEffect(() => {
    const paramToken = searchParams.get('token');
    if (paramToken) {
      dispatch(storeToken({ token: paramToken }));
      searchParams.delete('token');
      setSearchParam(searchParams);
    }
  }, [searchParams.get('token')]);

  const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev',
    trackingCallback: (experiment, result) => {
      track(
        'Experiment',
        'Viewed',
        {
          experimentId: experiment.key,
          variationId: result.variationId,
        },
        userData as ISegmentUserData,
      );
    },
  });

  growthbook.loadFeatures();

  const saveToken = (response: object | null) => {
    if (!response) {
      return;
    }

    const responseData = convertKeysCase(response) as {
      token: string,
    };
    dispatch(storeToken(responseData));
  };

  // [AS 2023-02-03] Special case for local development where you don't have
  // access to the Zendesk Application Framework and directly talk to the API
  if (
    process.env.REACT_APP_ENV === 'local'
    && process.env.REACT_APP_DUCKBILL_ZENDESK_USER_ID
  ) {
    axios.post(
      RETRIEVE_TOKEN_API,
      {
        zendesk_id: process.env.REACT_APP_DUCKBILL_ZENDESK_USER_ID,
        name: process.env.REACT_APP_DUCKBILL_ZENDESK_USER_NAME,
        email: process.env.REACT_APP_DUCKBILL_ZENDESK_USER_EMAIL,
      },
      {
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_DUCKBILL_ZENDESK_ACCESS_TOKEN}`,
        },
      },
    ).then((resp) => {
      saveToken(resp.data);
    });
  } else {
    const response = useClientRequestWithAuth(
      `${process.env.REACT_APP_API_URL}${RETRIEVE_TOKEN_API}`,
      {
        type: 'POST',
        data: JSON.stringify({
          zendesk_id: userData?.id || '',
          name: userData?.name || '',
          email: userData?.email || '',
        }),
      },
      [userData],
    ).data;

    useEffect(() => {
      saveToken(response);
    }, [response]);
  }

  const handleClient = async () => {
    try {
      dispatch(storeClientId(`${clientData?.externalId}`));
      const client = await dispatch(retrieveClient(`${clientData?.externalId}`)).unwrap();
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        clientId: client?.id,
        agentId: userData?.id,
      });
      await growthbook.refreshFeatures();
    } catch (e) {
      handleCatchErrorToast(e);
    }
  };

  const handleTask = async () => {
    try {
      dispatch(storeTaskId(`${taskData?.externalId}`));
      const task = await dispatch(retrieveTask(`${taskData?.externalId}`)).unwrap();
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        clientId: task?.client?.id,
        agentId: userData?.id,
      });
      await growthbook.refreshFeatures();
      navigate(`tasks/${taskData?.externalId}`);
    } catch (e) {
      handleCatchErrorToast(e);
    }
  };

  const organization = (
    taskData?.organization
    || (clientData?.organizations.length ? clientData?.organizations[0] : null)
  );

  useEffect(() => {
    if (
      token
      && (taskData || clientData)
      && (
        organization?.id.toString() !== process.env.REACT_APP_ZENDESK_ORGANIZATION_ID
        || !organization
      )
    ) {
      navigate('/wrong-organization');
    } else if (token && userData && taskData) {
      handleTask();
    } else if (token && userData && clientData) {
      handleClient();
    }
  }, [clientData, taskData, token]);

  useEffect(() => {
    if (userData) {
      identify(userData as ISegmentUserData);
      growthbook?.setAttributes({
        ...growthbook.getAttributes(),
        agentId: userData.id,
      });
    } else {
      window.analytics?.ready(() => {
        growthbook.setAttributes({
          ...growthbook.getAttributes(),
          anonymousId: window.analytics?.user().anonymousId,
        });
      });
    }
    growthbook.refreshFeatures();
  }, [userData]);

  return (
    <PersistGate loading={<Loader />} persistor={persistor}>
      <GrowthBookProvider growthbook={growthbook}>
        <AppRoutes />
      </GrowthBookProvider>
    </PersistGate>
  );
};

export default App;
